@charset "utf-8";

// Define defaults for each variable.

$base-font-family: "Ubuntu", sans-serif !default;
$base-font-size: 14px !default;
$base-font-weight: 400 !default;
$small-font-size: $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit: 30px !default;

$text-color: #333333 !default;
$background-color: #222233 !default;
$brand-color: #0858c0 !default;

$grey-color-dark: #333;
$grey-color: #666;
$grey-color-light: #ddd;
$grey-color-lighter: #fafafa;
$grayish-blue-color: #c9d6ef;
$dark-red-color: #a61c00;
$dark-blue-color: #457;

$table-text-align: left !default;

@mixin relative-font-size($ratio) {
	font-size: $base-font-size * $ratio;
}

$nav-height: 69px;
