#profile--picture {
	display: block;
	margin: 0 auto 10px;
	width: 128px;
	height: 128px;
	border-radius: 50%;
	box-shadow: 1px 2px 4px #aaa;
}

#profile--name {
	color: $grey-color-dark;
	text-align: center;
	margin: 0;
}

.profile--item {
	color: $grey-color;
	text-align: center;
	margin-top: 0;
	margin-bottom: 20px;
}

.profile--link {
	display: inline-block;
	width: 85px;

	span {
		display: inline-block;
		width: 100%;
	}
}
