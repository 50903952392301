.resume {
	font-size: 12px;

	h1 {
		color: #000000;
		font-size: 3em;
		font-weight: 600;
		text-transform: uppercase;
		margin-top: -15px;
		margin-bottom: 0;
	}

	h2 {
		color: #a61c00;
		font-size: 1.8em;
		font-weight: 600;
		text-transform: uppercase;
		margin-top: 4px;
		margin-bottom: 0;
	}

	h3 {
		color: #000000;
		font-size: 1.4em;
		font-weight: 600;
		margin-top: 4px;
		margin-bottom: 0;
	}

	p {
		color: #222222;
		font-size: 1em;
		margin-top: 0;
		margin-bottom: 0;
	}

	ul {
		margin-bottom: 0;
	}

	a {
		color: #1155cc;
		text-decoration: underline;
	}

	@media screen and (min-width: 768px) {
		&-row {
			display: flex;
		}

		&-col {
			width: 0;
			flex-grow: 1;
		}
	}
}
