/**
 * Reset some basic elements
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
	margin: 0;
	padding: 0;
}

/**
	* Basic styling
	*/
body {
	font: $base-font-weight #{$base-font-size}/#{$base-line-height}
		$base-font-family;
	color: $text-color;
	background-color: $background-color;
	-webkit-text-size-adjust: 100%;
	-webkit-font-feature-settings: "kern" 1;
	-moz-font-feature-settings: "kern" 1;
	-o-font-feature-settings: "kern" 1;
	font-feature-settings: "kern" 1;
	font-kerning: normal;
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}

/**
	* Set `margin-bottom` to maintain vertical rhythm
	*/
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
ol,
dl,
figure,
%vertical-rhythm {
	margin-bottom: $spacing-unit / 2;
}

/**
	* `main` element
	*/
main {
	display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}

/**
	* Images
	*/
img {
	max-width: 100%;
	vertical-align: middle;
}

/**
	* Figures
	*/
figure > img {
	display: block;
}

figcaption {
	font-size: $small-font-size;
}

/**
	* Lists
	*/
ul,
ol {
	margin-left: $spacing-unit;
}

li {
	> ul,
	> ol {
		margin-bottom: 0;
	}
}

/**
	* Headings
	*/
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: $base-font-weight;
}

/**
	* Links
	*/
a {
	color: $brand-color;
	text-decoration: underline;

	&:visited {
		color: darken($brand-color, 10%);
	}

	&:hover {
		color: $text-color;
		text-decoration: underline;
	}

	.social-media-list &:hover {
		text-decoration: none;

		.username {
			text-decoration: underline;
		}
	}
}

/**
	* Blockquotes
	*/
blockquote {
	color: $grey-color;
	border-left: 4px solid $grey-color-light;
	padding-left: $spacing-unit / 2;
	@include relative-font-size(1.125);
	letter-spacing: -1px;
	font-style: italic;

	> :last-child {
		margin-bottom: 0;
	}
}

/**
	* Code formatting
	*/
pre,
code {
	@include relative-font-size(0.9375);
	border: 1px solid $grey-color-light;
	border-radius: 3px;
	background: #fff;
}

code {
	color: $dark-red-color;
	padding: 1px 5px;
}

pre {
	padding: 8px 12px;
	overflow-x: auto;

	> code {
		border: 0;
		padding-right: 0;
		padding-left: 0;
	}
}

/**
	* Clearfix
	*/
%clearfix:after {
	content: "";
	display: table;
	clear: both;
}

/**
	* Icons
	*/

.svg-icon {
	width: 16px;
	height: 16px;
	display: inline-block;
	fill: #{$grey-color};
	padding-right: 5px;
	vertical-align: text-top;
}

.social-media-list {
	li + li {
		padding-top: 5px;
	}
}

/**
	* Tables
	*/
table {
	margin-bottom: $spacing-unit;
	width: 100%;
	text-align: $table-text-align;
	color: lighten($text-color, 18%);
	border-collapse: collapse;
	border: 1px solid $grey-color-light;
	tr {
		&:nth-child(even) {
			background-color: lighten($grey-color-light, 6%);
		}
	}
	th,
	td {
		padding: ($spacing-unit / 3) ($spacing-unit / 2);
	}
	th {
		background-color: lighten($grey-color-light, 3%);
		border: 1px solid darken($grey-color-light, 4%);
		border-bottom-color: darken($grey-color-light, 12%);
	}
	td {
		border: 1px solid $grey-color-light;
	}
}

@media print {
	.noprint {
		display: none!important;
	}

	.pagebreak {
		clear: both!important;
		// page-break-before: always!important;
		// page-break-inside: always!important;
		page-break-after: always!important;
	}
}
